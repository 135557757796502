<template>
  <el-drawer
    :modal-append-to-body="false"
    :title="lang('구성원등록')"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    size="50%"
    @close="drawerClose"
  >
    <panel>
      <el-button slot="button" size="small" @click="handleCancelClick()"
        >{{ lang("버튼.취소") }}
      </el-button>
      <el-button
        slot="button"
        size="small"
        type="primary"
        @click="handleSaveClick(true)"
      >
        {{ lang("버튼.입사발령하기") }}
      </el-button>
      <el-button slot="button" size="small" @click="handleSaveClick(false)"
        >{{ lang("버튼.입사발령안하기") }}
      </el-button>
      <el-form
        ref="form"
        label-width="100px"
        :model="model"
        label-position="left"
        :show-message="true"
        :rules="rules"
      >
        <el-row type="flex" align="middle" :gutter="12">
          <el-col :span="12">
            <el-form-item required prop="employeeName">
              <span slot="label">{{ lang("폼.성명") }}</span>
              <el-input
                v-model="model.employeeName"
                maxlength="100"
                size="small"
                @keyup.native="(event) => checkReg(event, 'employeeName')"
                @blur="(event) => checkRegBlur(event, 'employeeName')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12"> </el-col>
        </el-row>
        <el-row type="flex" align="middle" :gutter="12">
          <el-col :span="12">
            <el-form-item required prop="hireYmd">
              <span slot="label">{{ lang("폼.입사일") }}</span>
              <el-date-picker
                v-model="model.hireYmd"
                v-bind="datePicker"
                size="small"
                :placeholder="lang('폼.입사일')"
                value-format="yyyyMMdd"
                :disabled="model.copyTargetEmployeeId"
                @change="(val) => changeHireYmd(val)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12"> </el-col>
        </el-row>
        <el-row type="flex" align="middle" :gutter="12">
          <el-col :span="12">
            <el-form-item required prop="registrationNumber">
              <span slot="label">{{ lang("폼.주민번호") }}</span>
              <el-input
                v-model="model.registrationNumber"
                maxlength="13"
                size="small"
                :disabled="model.copyTargetEmployeeId"
                @focus="removeDash"
                @keyup.native="onlyNumberDash"
                @blur="(event) => addDash(event)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12"> </el-col>
        </el-row>
        <el-row type="flex" align="middle" :gutter="12">
          <el-col :span="12">
            <el-form-item>
              <span slot="label">{{ lang("폼.그룹웨어ID") }}</span>
              <el-input
                v-model="model.groupwareId"
                size="small"
                maxlength="100"
                @blur="(event) => checkRegBlur(event, 'groupwareId')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12"> </el-col>
        </el-row>
        <el-row type="flex" align="middle" :gutter="12">
          <el-col :span="12">
            <el-form-item required prop="organizationId">
              <span slot="label">{{ lang("폼.소속") }}</span>
              <el-input
                size="small"
                :disabled="findOrgDialogVisible"
                :value="model.organizationIdName"
                @keypress.enter.native="
                  (ev) => searchGridOrganization(ev.target.value)
                "
              >
                <i
                  slot="suffix"
                  class="el-icon-search el-input__icon"
                  style="cursor: pointer"
                  @click="searchGridOrganization(null)"
                />
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"> </el-col>
        </el-row>
        <el-row type="flex" align="middle" :gutter="12">
          <el-col :span="12">
            <el-form-item required prop="dutyCode">
              <span slot="label">{{ lang("폼.직책") }}</span>
              <el-select
                size="small"
                :value="model.dutyCode"
                @change="(val) => changeDutyCode(val)"
              >
                <el-option
                  v-for="option in dutyCodeList"
                  :key="`${option.lookupCode}_${option.lookupValueId}`"
                  :value="option.lookupValueId"
                  :label="option.meaning"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <span slot="label">{{ lang("폼.부서장여부") }}</span>
              <el-checkbox
                v-model="model.chiefYn"
                true-label="Y"
                false-label="N" /></el-form-item
          ></el-col>
        </el-row>

        <el-row type="flex" align="middle" :gutter="12">
          <el-col :span="12">
            <el-form-item required prop="assignmentCode">
              <span slot="label">{{ lang("폼.발령명") }}</span>
              <el-select
                size="small"
                :value="model.assignmentCode"
                @change="(val) => changeAssignmentCode(val)"
              >
                <el-option
                  v-for="option in assignmentCodeList"
                  :key="`${option.assignmentTypeCode}_${option.assignmentCode}`"
                  :value="option.assignmentCode"
                  :label="option.assignmentCodeName"
                /> </el-select></el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item prop="classCode">
              <span slot="label">{{ lang("폼.직급") }}</span>
              <code-select
                v-model="model.classCode"
                :placeholder="lang('폼.직급')"
                code="PHM_CLASS_CD"
                @change="(val) => (model.classCode = val)"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" align="middle" :gutter="12">
          <el-col :span="12">
            <el-form-item required prop="employeeKindCode">
              <span slot="label">{{ lang("폼.고용구분") }}</span>
              <code-select
                v-model="model.employeeKindCode"
                :placeholder="lang('폼.고용구분')"
                code="PHM_EMP_KIND_CD"
                @change="(val) => changeEmployeeKindCode(val)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item required prop="probationEndYmd">
              <span slot="label">{{ lang("폼.수습종료일") }}</span>
              <el-date-picker
                v-model="model.probationEndYmd"
                v-bind="datePicker"
                value-format="yyyyMMdd"
                size="small" /></el-form-item
          ></el-col>
        </el-row>

        <el-row type="flex" align="middle" :gutter="12">
          <el-col class="form-collapse">
            <i
              class="collapse-icon el-icon-caret-bottom"
              :class="{ rotate: isDetailNewVisible }"
            ></i>
            <span
              class="collapse-title"
              tabindex="0"
              @click="handleDetailNewToggle"
              @keyup.enter="handleDetailNewToggle"
              >{{
                isDetailNewVisible
                  ? lang("버튼.세부_설정_닫기")
                  : lang("버튼.세부_설정_열기")
              }}</span
            >
          </el-col>
        </el-row>

        <template v-if="isDetailNewVisible">
          <el-row type="flex" align="middle" :gutter="12">
            <el-col :span="12">
              <el-form-item required prop="nationalityCode">
                <span slot="label">{{ lang("폼.국적") }}</span>
                <code-select
                  v-model="model.nationalityCode"
                  :placeholder="lang('폼.국적')"
                  code="SYS_NATION_CD"
                  @change="(val) => (model.nationalityCode = val)"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item required prop="locationCode">
                <span slot="label">{{ lang("폼.근무지") }}</span>
                <code-select
                  v-model="model.locationCode"
                  :placeholder="lang('폼.근무지')"
                  code="ORM_LOCATION_CD"
                  @change="(val) => (model.locationCode = val)" /></el-form-item
            ></el-col>
          </el-row>
          <el-row
            v-for="(data, index) in phmAssignList"
            :key="index"
            :gutter="12"
            align="middle"
            type="flex"
          >
            <el-col :span="12">
              <el-form-item>
                <span slot="label">{{ data.meaning }}</span>
                <code-select
                  v-model="model['attribute0' + data.lookupCode.substr(0, 1)]"
                  :placeholder="data.meaning"
                  :code="
                    'PHM_ASSIGN_ATTRIBUTE0' +
                    data.lookupCode.substr(0, 1) +
                    '_CD'
                  "
                  :disabled="customDisabled(data.lookupCode.substr(0, 1))"
                  @change="
                    (val) =>
                      (model['attribute0' + data.lookupCode.substr(0, 1)] = val)
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="12"> </el-col>
          </el-row>
        </template>
      </el-form>
    </panel>
    <!-- 재입사자 조회 팝업 -->
    <el-dialog
      v-if="reEntrySearchDialogVisible"
      :title="lang('재입사자_조회')"
      :visible.sync="reEntrySearchDialogVisible"
      width="1000px"
    >
      <HAS1060
        v-if="reEntrySearchDialogVisible"
        :search-reg-num="reEntrySearchModel.registrationNumber"
        :search-hire-ymd="reEntrySearchModel.hireYmd"
        @close="(row) => setReEntryEmployeeInfo(row)"
      />
    </el-dialog>
    <!-- 부서찾기 -->
    <el-dialog
      v-if="findOrgDialogVisible"
      :visible.sync="findOrgDialogVisible"
      width="1000px"
    >
      <HOG1060
        v-if="findOrgDialogVisible"
        :value="gridSearch.orgStr"
        :assignment-time="hireYmd"
        :company-original-id="selectedCompanyOriginalId"
        @change="
          (data) =>
            setOrganization(
              data,
              gridSearch.selectedRow,
              gridSearch.selectedColumn,
            )
        "
        @close="resetState"
      />
    </el-dialog>
  </el-drawer>
</template>

<script>
import { i18nTranslator } from "@/mixins/i18n";
import { authEventUtil } from "@/mixins/auth";
import moment from "moment";
import Panel from "@/components/portlets/Panel";
import CodeSelect from "@/components/form/CodeSelect";
import validator from "@/lib/validator";
import { CommonAPI } from "@/services/api";
import helpers from "@/auth/helpers";
import HAS1060 from "@/pages/HAS/HAS1060.vue";
import HOG1060 from "@/pages/POPUP/HOG1060.vue";
import masterModel from "@/services/model/HAS/HAS1030M01";
import date from "@/lib/date";

export default {
  name: "EmployeeRegistration",
  components: {
    Panel,
    CodeSelect,
    HAS1060,
    HOG1060,
  },
  mixins: [
    i18nTranslator("EmployeeRegistration"),
    authEventUtil("EmployeeRegistration"),
  ],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    selectedCompanyOriginalId: {
      type: Number,
      default: null,
    },
    msgFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultEmployeeKindCode: null,
      defaultNationalityCode: null,
      defaultDutyCode: null,
      defaultLocationCode: null,
      camCodeList: [],
      classCodeList: [],
      dutyCodeList: [],
      organizationList: [],
      nationalityCodeList: [],
      locationCodeList: [],
      hireRouteCodeList: [],
      phmAssignList: [],
      assignmentCodeList: [],
      model: {
        manipulationType: "C",
        employeeName: null, //  필수
        hireYmd: null, // 필수
        registrationNumber: null, // 필수
        groupwareId: null,
        organizationId: null, //
        dutyCode: null,
        chiefYn: null,
        classCode: null,
        assignmentCode: null,
        employeeKindCode: null,
        probationEndYmd: null,
        nationalityCode: null,
        locationCode: null,
        hireRouteCode: null,
        attribute01: null,
        attribute02: null,
        attribute03: null,
        attribute04: null,
        attribute05: null,
        attribute06: null,
        note: null,
        copyTargetEmployeeId: null,
      },
      isDetailNewVisible: false,
      reEntrySearchDialogVisible: false,
      reEntrySearchModel: {
        selectedRow: {},
        registrationNumber: null,
        hireYmd: null,
      },
      findOrgDialogVisible: false,
      gridSearch: {
        rowData: {},
        useFlag: false,
        selectedRow: null,
        orgStr: null,
        commentStr: null,
        companyOriginalId: null,
        organizationIdName: null,
      },
      hireYmd: null,
      phmAssignDetail: {},
    };
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    rules() {
      return {
        employeeName: [
          {
            required: true,
            message: this.lang("msg.requirementInvalid", "name", {
              name: this.lang("폼.성명"),
            }),
          },
        ],
        hireYmd: [
          {
            required: true,
            message: this.lang("msg.requirementInvalid", "name", {
              name: this.lang("폼.입사일"),
            }),
          },
        ],
        registrationNumber: [
          {
            required: true,
            message: this.lang("msg.requirementInvalid", "name", {
              name: this.lang("폼.주민번호"),
            }),
          },
        ],
        organizationId: [
          {
            required: true,
            message: this.lang("msg.requirementInvalid", "name", {
              name: this.lang("폼.소속"),
            }),
          },
        ],
        dutyCode: [
          {
            required: true,
            message: this.lang("msg.requirementInvalid", "name", {
              name: this.lang("폼.직책"),
            }),
          },
        ],
        assignmentCode: [
          {
            required: true,
            message: this.lang("msg.requirementInvalid", "name", {
              name: this.lang("폼.발령명"),
            }),
          },
        ],
        employeeKindCode: [
          {
            required: true,
            message: this.lang("msg.requirementInvalid", "name", {
              name: this.lang("폼.고용구분"),
            }),
          },
        ],
        probationEndYmd: [
          {
            required: true,
            message: this.lang("msg.requirementInvalid", "name", {
              name: this.lang("폼.수습종료일"),
            }),
          },
        ],
        nationalityCode: [
          {
            required: true,
            message: this.lang("msg.requirementInvalid", "name", {
              name: this.lang("폼.국적"),
            }),
          },
        ],
        locationCode: [
          {
            required: true,
            message: this.lang("msg.requirementInvalid", "name", {
              name: this.lang("폼.근무지"),
            }),
          },
        ],
      };
    },
  },
  async mounted() {
    const [a, b, c, d, e, f, g, h] = await Promise.all([
      this.setCodeData("CAM_CAU_CD"),
      this.setCodeData("SYS_NATION_CD"),
      this.setCodeData("PHM_DUTY_CD"),
      this.setCodeData("ORM_LOCATION_CD"),
      this.setCodeData("PHM_EMP_KIND_CD"),
      this.setCodeData("PHM_HIRE_ROUTE_CD"),
      this.setCodeData("PHM_CLASS_CD"),
      this.setCodeData("PHM_ASSIGN_ATTRIBUTE"),
    ]);
    this.camCodeList = a;
    this.nationalityCodeList = b;
    this.dutyCodeList = c;
    this.locationCodeList = d;
    this.employeeKindCodeList = e;
    this.hireRouteCodeList = f;
    this.classCodeList = g;
    this.phmAssignList = h; // 발령 추가 컬럼

    for (const nationCode of b) {
      if (nationCode.lookupCode === "KR") {
        this.defaultNationalityCode = nationCode.lookupValueId;
        break;
      }
    }
    for (const dutyCode of c) {
      if (dutyCode.lookupCode === "301") {
        this.defaultDutyCode = dutyCode.lookupValueId;
        break;
      }
    }
    for (const locationCode of d) {
      if (locationCode.lookupCode === "K01") {
        this.defaultLocationCode = locationCode.lookupValueId;
        break;
      }
    }
    for (const employeeKindCode of e) {
      if (employeeKindCode.lookupCode === "1") {
        this.defaultEmployeeKindCode = employeeKindCode.lookupValueId;
        break;
      }
    }

    await this.handleCancelClick();

    await this.assignmentName();

    // 발령 추가 컬럼의 상세 공통코드 동적으로 처리
    for (let i in this.phmAssignList) {
      if (i > 5) {
        break;
      }

      const response = await CommonAPI.getCodes({
        lookupType:
          "PHM_ASSIGN_ATTRIBUTE0" +
          this.phmAssignList[i].lookupCode.substr(0, 1) +
          "_CD",
      });

      this.phmAssignDetail[
        "PHM_ASSIGN_ATTRIBUTE0" +
          this.phmAssignList[i].lookupCode.substr(0, 1) +
          "_CD"
      ] = response.data.payload;
    }
  },
  methods: {
    handleDetailNewToggle() {
      this.isDetailNewVisible = !this.isDetailNewVisible;
    },
    async setCodeData(code) {
      const {
        data: { payload },
      } = await CommonAPI.getCodes({
        lookupType: code,
      });

      return payload;
    },
    checkRegBlur(event, string) {
      const target = event.target;

      if (string === "employeeName") {
        this.model.employeeName = validator.replacePattern(
          event.target.value,
          "specialCharacter",
        );
      } else if (string === "groupwareId") {
        this.model.groupwareId = validator.extractPattern(
          target.value.replace(/ /gi, ""),
          "email",
        );
      }
    },
    checkReg(event, string) {
      const target = event.target;
      if (string === "employeeName") {
        this.model.employeeName = validator.replacePattern(
          target.value,
          "specialCharacter",
        );
      }
    },
    async changeHireYmd(hireYmd) {
      this.model.hireYmd = hireYmd;
      let probationEndYmd = await this.getProbationEndYmd(
        this.model.assignmentCode,
        true,
      );

      this.model.probationEndYmd = probationEndYmd;
    },
    async getProbationEndYmd(val, force = false) {
      let probationEndYmd;
      if (!this.model.hireYmd) return (probationEndYmd = null);
      if (this.isProbationEndDateUse(val)) {
        if (force || !this.model.probationEndYmd) {
          probationEndYmd = await this.getAddedProbationEndYmd(
            this.model.hireYmd,
          );
        } else {
          probationEndYmd = this.model.probationEndYmd;
        }
      } else {
        probationEndYmd = null;
      }
      return probationEndYmd;
    },
    isProbationEndDateUse(val) {
      return this.assignmentCodeList.some(
        (e) => e.assignmentCode === val && e.probationEndDateYn === "Y",
      );
    },
    getAddedProbationEndYmd() {
      // 고용구분에 따른 값 변경
      const code = this.employeeKindCodeList.find(
        (vo) => vo.lookupValueId === this.model.employeeKindCode,
      );
      if (
        code &&
        code.attribute02 === "Y" &&
        code.attribute03 === "10" &&
        code.attribute04
      ) {
        return moment(this.model.hireYmd)
          .add(Number(code.attribute04), "months")
          .format("YYYYMMDD");
      } else if (
        code &&
        code.attribute02 === "Y" &&
        code.attribute03 === "20" &&
        code.attribute04
      ) {
        return moment(this.model.hireYmd)
          .add(Number(code.attribute04), "days")
          .format("YYYYMMDD");
      } else {
        return moment(this.model.hireYmd).add(3, "months").format("YYYYMMDD");
      }
    },
    removeDash(event) {
      const target = event.target;
      target.value = validator.removeDash(target.value);
    },
    onlyNumberDash(event) {
      const target = event.target;
      target.value = validator.extractLength(
        validator.replacePattern(target.value, "numNot"),
        1,
        13,
      );
    },
    async addDash(event) {
      if (event.target.value.length === 13) {
        this.model.registrationNumber = validator.replaceRegnumAddDash(
          event.target.value,
        );
        await this.searchReEntryEmployeeInfo(event);
      } else {
        this.model.registrationNumber = null;
      }
    },
    async searchReEntryEmployeeInfo(event) {
      try {
        const {
          data: { payload },
        } = await helpers.get(`/assignments/entrada`, {
          registrationNumber: event.target.value.replace(/-/gi, ""),
        });

        if (payload.length) {
          this.reEntrySearchModel.selectedRow = this.model;
          this.reEntrySearchModel.registrationNumber =
            event.target.value.replace(/-/gi, "");
          this.reEntrySearchModel.hireYmd = this.model.hireYmd;
          this.reEntrySearchDialogVisible = true;
        }
      } finally {
        // do nothing
      }
    },
    setReEntryEmployeeInfo(row) {
      if (row) {
        let targetLookupCode = (
          this.camCodeList.filter(
            (e) => e.lookupValueId === row.retireCode,
          )[0] || {}
        ).lookupCode;

        let assignmentCode;
        // 그룹사전출: 그룹사전입, 그 외 발령: 재입사로 세팅
        if (targetLookupCode === "706") {
          assignmentCode = this.getAssignmentCode("104");
        } else {
          assignmentCode = this.getAssignmentCode("105");
        }

        let { employeeId, groupwareId, retireYmd } = row;
        let changedRows = {
          ...this.reEntrySearchModel.selectedRow,
          copyTargetEmployeeId: employeeId,
          hireYmd:
            targetLookupCode === "706"
              ? moment(retireYmd).add(1, "day").format("YYYYMMDD")
              : this.reEntrySearchModel.selectedRow.hireYmd,
          assignmentCode,
          groupwareId,
          employeeKindCode: this.getEmployeeKindCode(assignmentCode),
        };

        this.model = Object.assign(this.model, changedRows);
      }

      this.reEntrySearchDialogVisible = false;
      this.reEntrySearchModel.selectedRow = null;
      this.reEntrySearchModel.registrationNumber = null;
      this.reEntrySearchModel.hireYmd = null;
    },
    getAssignmentCode(val) {
      let targetVal =
        this.camCodeList.filter((e) => e.lookupCode === val)[0] || {};
      return (
        this.assignmentCodeList.filter(
          (e) => e.assignmentCode === targetVal.lookupValueId,
        )[0] || {}
      ).assignmentCode;
    },
    handleCancelClick() {
      this.model = {
        manipulationType: "C",
        companyOriginalId: this.selectedCompanyOriginalId,
        companyCode: null,
        employeeName: null,
        registrationNumber: null, // 필수
        groupwareId: null,
        organizationId: null, //
        dutyCode: this.defaultDutyCode,
        chiefYn: null,
        classCode: null,
        assignmentCode: (
          this.camCodeList.filter((r) => r.lookupCode === "102")[0] || {}
        ).lookupValueId,
        employeeKindCode: this.defaultEmployeeKindCode,
        probationEndYmd: null,
        nationalityCode: this.defaultNationalityCode,
        locationCode: this.defaultLocationCode,
        hireRouteCode: null,
        attribute01: null,
        attribute02: null,
        attribute03: null,
        attribute04: null,
        attribute05: null,
        attribute06: null,
        copyTargetEmployeeId: null,
      };
    },
    async assignmentName() {
      const {
        data: { payload },
      } = await helpers.get("/assignments/historybasic/details", {
        assignmentTypeCode: 10,
        companyOriginalId: this.selectedCompanyOriginalId,
      });

      this.assignmentCodeList = payload;
    },
    async searchGridOrganization(value) {
      if (value && value !== "") {
        const response = await helpers.get("/com/organization", {
          inputText: value,
          limit: 2,
          companyOriginalId: this.model.companyOriginalId,
        });
        const payload = response.data.payload;
        const filteredList = payload.filter(
          (o) => o.organizationName === value,
        );
        if (filteredList.length === 1) {
          this.setOrganization(filteredList[0]);
          return;
        }
      }
      this.hireYmd = this.model.hireYmd;
      this.gridSearch.orgStr = value;
      this.gridSearch.selectedRow = this.model;
      this.findOrgDialogVisible = true;
    },
    setOrganization(org) {
      const {
        organizationName,
        organizationId,
        companyOriginalId,
        companyOriginalIdName,
        organizationCode,
        locationCode,
      } = org;

      if (this.model.companyOriginalId !== companyOriginalId) {
        this.$message({
          type: "warning",
          message: this.lang("메세지.선택불가법인"),
        });
        return;
      }

      this.model = Object.assign(this.model, {
        organizationId,
        organizationIdName: organizationName,
        organizationCode,
        companyOriginalId,
        companyOriginalIdName,
        locationCode,
      });
      this.resetState();
    },
    resetState() {
      this.gridSearch.selectedRow = null;
      this.gridSearch.orgStr = null;
      this.gridSearch.commentStr = null;
      this.findOrgDialogVisible = false;
    },
    changeDutyCode(dutyCode) {
      let chiefYn = "N";
      for (const code of this.dutyCodeList) {
        if (code.lookupValueId == dutyCode && code.attribute01 == "Y")
          chiefYn = "Y";
      }
      let changedColumns = {
        dutyCode,
        chiefYn,
      };

      this.model = Object.assign(this.model, changedColumns);
    },
    async changeAssignmentCode(assignmentCode) {
      let employeeKindCode = this.getEmployeeKindCode(assignmentCode);

      const probationEndYmd = await this.getProbationEndYmd(
        assignmentCode,
        true,
      );

      if (this.model.copyTargetEmployeeId) {
        this.model.copyTargetEmployeeId = null;
        this.$message({
          type: "warning",
          message: this.lang("메세지.재입사자재선택"),
        });
      }
      const changedColumns = {
        assignmentCode,
        probationEndYmd,
        employeeKindCode,
        attribute01: null,
        attribute02: null,
        attribute03: null,
        attribute04: null,
        attribute05: null,
        attribute06: null,
      };
      this.model = Object.assign(this.model, changedColumns);
    },
    async changeEmployeeKindCode(employeeKindCode) {
      let { assignmentCode } = this.model;
      let vo = {
        ...this.model,
        employeeKindCode,
      };
      let probationEndYmd = await this.getProbationEndYmd(
        assignmentCode,
        vo,
        true,
      );
      let changedColumns = {
        ...this.model,
        probationEndYmd,
        employeeKindCode,
      };
      this.model = Object.assign(this.model, changedColumns);
    },
    getEmployeeKindCode(val) {
      return this.isContractHire(val)
        ? (
            this.employeeKindCodeList.filter((e) => e.lookupCode === "2")[0] ||
            {}
          ).lookupValueId
        : (
            this.employeeKindCodeList.filter((e) => e.lookupCode === "1")[0] ||
            {}
          ).lookupValueId;
    },
    isContractHire(val) {
      return this.assignmentCodeList.some(
        (e) => e.assignmentCode === 60831 && e.assignmentCode === val,
      );
    },
    customDisabled(code) {
      const assignmentCode = this.assignmentCodeList.filter(
        (vo) => vo.assignmentCode === this.model.assignmentCode,
      );

      return assignmentCode[0]["attribute0" + code + "Yn"] === "N";
    },
    async handleSaveClick(flag) {
      const isFormValid = await this.$refs.form.validate();
      if (!isFormValid) {
        return;
      }
      let loading;
      let data = [this.model];
      if (data.length < 1 || this.pending) return;

      const companyOriginalId = this.selectedCompanyOriginalId;

      if (data.every((value) => value.manipulationType !== "D")) {
        const filterFlag = data
          .filter((item) => item.xlsx)
          .every((val) => val.companyOriginalId === companyOriginalId);

        if (!filterFlag) {
          this.$message({
            type: "warning",
            message: this.lang("메세지.소속법인재확인"),
          });
          return;
        }
      }

      let rules = [
        {
          validator: (row) => {
            let empNumLen = 0;
            try {
              empNumLen = row.employeeNumber.length;
            } catch (e) {
              // continue regardless of error
            }
            return empNumLen !== 0 && empNumLen > 20;
          },
          msg: "메세지.사번길이제한",
        },
        {
          validator: (row) => {
            const birthDate = `${row.registrationNumber.substr(0, 6)}`;
            return !this.$moment(birthDate, "YYMMDD").isValid();
          },
          msg: "메세지.주민등록번호형식",
        },
      ];
      // 추가 컬럼에 대한 유효성체크
      for (let i in this.phmAssignList) {
        if (i > 5) {
          break;
        }

        rules.push({
          validator: (row) => {
            const assignment = this.assignmentCodeList.find(
              (vo) => vo.assignmentCode === row.assignmentCode,
            );
            return (
              assignment &&
              assignment[
                "attribute0" +
                  this.phmAssignList[i].lookupCode.substr(0, 1) +
                  "Yn"
              ] === "Y" &&
              !row["attribute0" + this.phmAssignList[i].lookupCode.substr(0, 1)]
            );
          },
          msg: "메세지.추가컬럼필수",
          labal: this.phmAssignList[i].meaning,
        });
      }

      for (let i in data) {
        const row = data[i];

        if (row.probationEndYmd) {
          if (moment(row.hireYmd).diff(moment(row.probationEndYmd)) > 0) {
            return this.$message({
              type: "warning",
              message: this.lang("메세지.입사일수습일재선택필요"),
            });
          }
        }
        for (let rule of rules) {
          let valid = rule.validator(row);

          if (valid) {
            this.$message({
              type: "warning",
              message: (rule?.labal || "") + this.lang(rule.msg),
            });

            this.$refs.table.setCurrentRow(row);
            return;
          }
        }
        for (let code of this.dutyCodeList) {
          if (
            row.chiefYn === "Y" &&
            row.dutyCode === code.lookupValueId &&
            code.attribute01 !== "Y"
          )
            row.chiefYn = "N";
        }
      }

      try {
        loading = this.$loading({
          lock: true,
          text: "Loading...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let res;
        res = await helpers.put(
          `/assignments/employees`,
          data.map((el) => {
            el.companyCode = 10;
            el.hireYmd = moment(el.hireYmd).format("YYYYMMDD");
            el.registrationNumber = validator.removeDash(el.registrationNumber);
            if (el.probationEndYmd) {
              el.probationEndYmd = moment(el.probationEndYmd).format(
                "YYYYMMDD",
              );
            } else {
              el.probationEndYmd = null;
            }

            el.groupwareId =
              el.groupwareId && el.groupwareId.trim()
                ? el.groupwareId.replace(/ /gi, "")
                : null;
            el.dualNationalityCode =
              el.dualNationalityCode && el.dualNationalityCode
                ? el.dualNationalityCode
                : null;
            el.dualNationalityCodeName = null;
            return el;
          }),
        );

        if (res.data.payload) {
          if (flag) {
            // 입사발령 처리
            // 해당 되는 사원의 employeeNumber, employeeName, previousHireId 조회
            const {
              data: { payload },
            } = await helpers.get(`/assignments/employees`, {
              companyOriginalId: this.selectedCompanyOriginalId || undefined,
              hireYmdEnd: this.model.hireYmd,
              hireYmdStart: this.model.hireYmd,
              masking: false,
            });

            const info = payload.filter(
              (vo) =>
                vo.employeeName === this.model.employeeName &&
                vo.registrationNumber === this.model.registrationNumber,
            );

            this.model = Object.assign(this.model, {
              employeeNumber: info[0].employeeNumber,
              previousHireId: info[0].previousHireId,
              manipulationType: "U",
              checkboxSelect: "Y",
            });

            const userInfo = this.$store.getters["user/getUserInfo"];
            const { userName, employeeNumber } = userInfo;
            const {
              data: {
                payload: { documentNo, documentId },
              },
            } = await helpers.put(`/assdocument/document`, [
              {
                ...masterModel,
                manipulationType: "C",
                companyOriginalId: this.selectedCompanyOriginalId,
                roweditYn: "Y",
                applyYmd: date.nowDate(),
                employeeName: userName,
                employeeNumber,
                documentTitle: this.$tc("el.table.assignDocumentTitle"),
              },
            ]);

            // 현재 모델에 넣어준다.
            this.model = Object.assign(this.model, {
              documentId,
              documentNo,
            });

            await this.$nextTick();

            const list = [];

            list.push(this.model);

            const res = await helpers.put(
              `/assignments/${documentId}/${this.model.hireYmd}/${this.model.hireYmd}/employees/previous`,
              list,
            );

            const {
              data: { payload: p },
            } = await helpers.put(`/assdocument/${documentId}/historyfixed`, {
              documentId,
              documentNo,
              documentTitle: "?",
              applyYmd: this.$moment().format("YYYYMMDD"),
              companyOriginalId: this.selectedCompanyOriginalId,
              chiefYn: this.model.chiefYn,
              classCode: this.model.classCode,
            });

            // 발령성공 시 oErrNo = '0'
            if (p.oErrNo !== "0") {
              this.$nextTick(() => {
                this.$message({
                  type: "info",
                  message: this.$tc("msg.assignmentInComplete"),
                });
                this.$router.replace(
                  {
                    name: "HAS1030",
                    params: {
                      documentNo: documentNo,
                    },
                  },
                  () => {},
                  () => {},
                );
              });
            }

            if (res.data.payload && p.oErrNo === "0") {
              this.$message({
                type: "success",
                message: this.$tc("el.table.saveAssignments"), // 발령등록 되었습니다.
              });
            }

            await this.$nextTick();

            // 팝업 닫고 조회
            this.$emit("close");
          } else {
            if (this.msgFlag) {
              this.$message({
                type: "info",
                message: this.lang("메세지.사원등록확인"),
              });
            } else {
              this.$message({
                type: "success",
                message: this.lang("msg.saveSuccess"),
              });
            }
            // 팝업 닫고 조회
            this.$emit("close");
          }
        }
      } catch (e) {
        loading && loading.close();
        console.log(e);
      } finally {
        loading && loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form .el-row:not(:last-child) {
  margin-bottom: 20px;
}
</style>
